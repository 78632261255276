<div #tiles id="tiles"></div>
<app-back-button [pageName]="home" *ngIf="!viewpoint.main">
</app-back-button>

<app-horizontal-toolbar [viewpoint]="viewpoint" [viewpoints]="viewpoints"
  [title]="viewpoint.main ? 'More viewpoints' : 'Viewpoints'" [toggleBtnTop]="82"></app-horizontal-toolbar>
<app-switch *ngIf="viewpoint.mode['existing']" [viewpoint]="viewpoint" [checked]="state.global.mode === 'proposed'"
  (check)="toggleScene($event)" (checkLayer)="toggleLayerScene($event)"></app-switch>

<app-minimap [viewer]="viewer" [viewpoint]="viewpoint" [viewpoints]="viewpoints" [zoom]="16"></app-minimap>
<app-compass [viewpoint]="viewpoint" [viewer]="viewer"></app-compass>
<app-zoom-indicator [viewer]="viewer" [ngClass]="{'embedded':!standalone}"></app-zoom-indicator>
<app-hotspot *ngFor="let sceneHotspot of sceneHotspots" [scene]="sceneHotspot.scene" [data]="sceneHotspot.hotspot"
  [sceneId]="sceneHotspot.sceneId"></app-hotspot>

<app-walkthrough *ngIf="!viewpoint.main" [data]="walkthrough.externalViewpoints"></app-walkthrough>
<app-walkthrough *ngIf="viewpoint.main" [data]="walkthrough.home"></app-walkthrough>