import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TransitionService } from '@shared/services/transition-service.service';
import { ViewpointsService } from '@shared/services/viewpoints.service';
import { Viewer } from 'marzipano';
import { sharedHotspots, walkthrough } from '@shared/data/media.data.json';
import { StateService } from '@shared/services/state.service';
import { Viewpoint, Hotspot, Walkthrough } from '@models';
import { AppService } from 'src/app/app.service';

/**
 * Component for viewpoint page. Viewpoints may have existing and proposed screens, night (dark) screen, and Easter Egg screen.
 * "Proposed" mode may also have layers (overlay, vegetation, etc).
 */
@Component({
  selector: 'app-viewpoint',
  templateUrl: './viewpoint.component.html',
  styleUrls: ['./viewpoint.component.scss'],
})
export class ViewpointComponent implements OnInit {
  @ViewChild('tiles') tiles: ElementRef;
  home: string;
  standalone: boolean;
  viewer: Viewer = {};
  viewpoint: Viewpoint;
  sceneHotspots: any = [];
  viewpoints: Viewpoint[];
  sharedHotspots: any;
  walkthrough: Walkthrough;
  state: any;
  heading: string;
  title: string;
  private existingScene: any;
  private proposedScene: any;
  private currentProposedScene: any; // this is used to keep track of layer scenes
  private darkScene: any;
  private easterEggScene: any;
  private refreshId: any;

  constructor(
    private route: ActivatedRoute,
    private viewpointsService: ViewpointsService,
    private transitionService: TransitionService,
    private stateService: StateService,
    private appService: AppService,
    private router: Router
  ) {
    // force route reload whenever params change
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.sharedHotspots = sharedHotspots;
    this.walkthrough = walkthrough;
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      let viewpointSlug = params['name'];
      this.viewpoints = this.viewpointsService.getViewpoints();
      if (!viewpointSlug) {
        viewpointSlug = this.viewpointsService.getMainViewpoint().slug;
        this.home = null;
      }
      this.heading = this.appService.heading;
      this.title = this.appService.title;
      this.standalone = this.appService.isStandalone;
      this.home = this.appService.home;
      this.viewpoint = this.viewpointsService.getViewpoint(viewpointSlug, true);
      // this.type = this.viewpoint.main ? 'existing' : 'proposed';
      this.state = this.stateService.getState();
      if (!this.state.viewpoints[this.viewpoint.slug]) {
        this.state.viewpoints[this.viewpoint.slug] = {};
        this.stateService.setState(this.state);
      }
    });
  }

  ngAfterViewInit(): void {
    this.initPanos();
  }

  ngOnDestroy(): void {
    // this.viewer.destroy();
    this.stop();
  }

  /**
   * Create the hotspots needed for a scene.
   * @param scene
   * @param viewpoint
   * @param type
   * @param altId
   */
  createSceneHotspots(scene, viewpoint, type, altId = '') {
    let hotspots: Hotspot[] = viewpoint.mode[type].hotspots;
    if (viewpoint.mode[type].sharedHotspotsSlug) {
      hotspots = this.sharedHotspots[viewpoint.mode[type].sharedHotspotsSlug];
    }
    for (let i = 0; i < hotspots.length; i++) {
      this.sceneHotspots.push({
        scene: scene,
        sceneId: viewpoint.slug + '-' + altId,
        hotspot: hotspots[i],
      });
    }
  }

  /**
   * Set up the Marzipano panos and hotspots needed for the current viewpoint.
   */
  initPanos() {
    const panoElement = this.tiles.nativeElement;
    this.viewer = new Viewer(panoElement, {
      stage: { progressive: true },
    });
    if (this.viewpoint.mode['proposed']) {
      this.proposedScene = this.createScene(
        this.viewpoint,
        this.viewpoint.mode['proposed'].slug
      );
      this.createSceneHotspots(this.proposedScene, this.viewpoint, 'proposed');
      if (this.viewpoint.mode['proposed'].darkSceneSlug) {
        this.darkScene = this.createScene(
          this.viewpoint,
          this.viewpoint.mode['proposed'].darkSceneSlug
        );
        this.createSceneHotspots(
          this.darkScene,
          this.viewpoint,
          'proposed',
          'dark'
        );
      }
      if (this.viewpoint.mode['proposed'].easterEggSceneSlug) {
        this.easterEggScene = this.createScene(
          this.viewpoint,
          this.viewpoint.mode['proposed'].easterEggSceneSlug
        );
      }
    }
    if (this.viewpoint.mode['existing']) {
      this.existingScene = this.createScene(
        this.viewpoint,
        this.viewpoint.mode['existing'].slug
      );
    }
    if (this.existingScene && this.state.global.mode === 'existing') {
      this.transitionService.currentScene = this.existingScene;
    } else {
      this.transitionService.currentScene = this.proposedScene;
    }
    this.currentProposedScene = this.proposedScene;
    this.transitionService.currentScene.switchTo();
    this.start();
  }

  /**
   * Create a scene for the current viewpoint.
   * @param viewpoint
   * @param sceneSlug
   * @returns
   */
  private createScene(viewpoint, sceneSlug) {
    let scene = this.transitionService.createMarzipanoScene(
      viewpoint,
      this.viewer,
      `../../assets/tiles/${sceneSlug}/{z}/{f}/{y}/{x}.jpg`
    );
    return scene;
  }

  /**
   * Toggle the scene "mode" between "existing" and "proposed".
   * @param args
   */
  public toggleScene = (args): void => {
    let mode = 'existing';
    if (args.checked) {
      mode = 'proposed';

      /***DON'T REMOVE - this was commented out because a minor bug with global toggle state */
      // let layerToggleState = this.stateService.getViewpointState(
      //   this.viewpoint.slug,
      //   'toggleState'
      // );
      // if (layerToggleState) {
      //   this.currentProposedScene = this.existingScene;
      //   this.state.global.mode = mode;
      //   this.stateService.setState(this.state);
      //   return this.toggleLayerScene(layerToggleState);
      // }
      /***END DON'T REMOVE */
    }
    this.transitionService
      .nextScene(this.existingScene, this.currentProposedScene)
      .switchTo({
        transitionDuration: this.transitionService.TRANSITION_DURATION,
      });
  };

  /**
   * Toggle the layer scene. Uses data in the layers.map data structure in "shared/data/data.json".
   * @param args
   */
  public toggleLayerScene = (args: any): void => {
    let layerScene = null;
    let overlayChecked = args.overlay.checked ? 'on' : 'off';
    let vegetationChecked = args.vegetation.checked ? 'on' : 'off';

    let slug =
      this.viewpoint.mode['proposed'].layers.map.overlay[overlayChecked]
        .vegetation[vegetationChecked].slug;
    this.state.toggleState = args;
    this.state = this.stateService.setViewpointState(
      this.viewpoint.slug,
      'toggleState',
      args
    );
    layerScene = this.createScene(this.viewpoint, slug);
    this.transitionService
      .nextScene(this.currentProposedScene, layerScene)
      .switchTo({
        transitionDuration: this.transitionService.TRANSITION_DURATION,
      });
    this.transitionService.currentScene = layerScene;
    this.currentProposedScene = layerScene;
  };

  // NOTE: from https://github.com/google/marzipano/issues/282

  /**
   * Creates a new layer for the given scene and returns a promise that will be resolved when all data for that layer has finished downloading all the layer tiles.
   * In order for the promise to resolve, the scene we're adding the layer to MUST BE THE CURRENT SCENE. This is because the layers need to be part of the stage for the
   * 'renderComplete' event to fire, and they're only added to the stage when that scene is switched to. The layer has opacity = 0 and hide = true when created.
   * @param {Scene} mScene Marzipano scene to create the new layer in
   * @param {string} newPanoBaseUrl Base URL for the pano tiles. Basically the folder where the tiles are stored.
   */
  //  createMarzipanoSceneLayer(mScene, newPanoBaseUrl) {
  // 	const origLayer = mScene.listLayers()[0];
  // 	const newLayer = mScene.createLayer({
  // 		source: createImageSource(newPanoBaseUrl),
  // 		geometry: origLayer.geometry(), // keep the geometry the same as it was
  // 		pinFirstLevel: true,
  // 		layerOpts: {
  // 			effects: {
  // 				opacity: 0, // start out invisible until everything is loaded since this new layer is created on top
  // 				hide: true
  // 			}
  // 		}
  // 	});

  // 	return new Promise((resolve) => {
  // 		mScene.viewer().stage().addEventListener('renderComplete', renderComplete); // NOTE: THIS WILL NOT FIRE WITH 'TRUE' UNLESS WE'RE ACTUALLY ON THAT SCENE ALREADY!

  // 		function renderComplete(renderingFinished) {
  // 			if (!renderingFinished) { return; } // if this event provides a false value, we're not done yet

  // 			// If we make it here, all visible tile textures must be loaded
  // 			mScene.viewer().stage().removeEventListener('renderComplete', renderComplete); // remove the listener so it's no longer called
  // 			resolve(newLayer);
  // 		}
  // 	});
  // }

  /**
   * Toggle the Dark scene.
   * @param $event
   */
  public toggleDarkScene = ($event): void => {
    this.transitionService
      .nextScene(this.proposedScene, this.darkScene)
      .switchTo({
        transitionDuration: this.transitionService.TRANSITION_DURATION,
      });
  };

  /**
   * Toggle the Easter Egg scene.
   * @param $event
   */
  public toggleEasterEggScene = ($event): void => {
    this.transitionService
      .nextScene(this.proposedScene, this.easterEggScene)
      .switchTo({
        transitionDuration: this.transitionService.TRANSITION_DURATION,
      });
  };

  /**
   * Starts the polling using in the compass and map viewcone rotation.
   */
  start() {
    let that = this;
    this.refreshId = setInterval(function () {
      let viewParams = that.viewer.view().parameters();
      that.viewpoint.yaw = viewParams.yaw;
      that.viewpointsService.onUpdateViewpoint.emit(that.viewpoint);
    }, 100);
  }

  /**
   * Clear the polling using in the compass and map viewcone rotation.
   */
  stop() {
    clearInterval(this.refreshId);
  }
}
